import {AbstractComponent, ComponentLoader} from "ui-base/src/AbstractComponent";

declare const $: any;

export class Navigation extends AbstractComponent {
    public static selector: string = 'navigation';
    private menuOpen = false;

    init() {
        super.init();

        this.getComponentElement().find('[data-burger-menu]').on('click', (e) => {
            $('body').attr('data-menu', this.menuOpen ? "close" : "open");
            this.menuOpen = !this.menuOpen;
        });
    }
}

// Setup the component loader
new ComponentLoader(Navigation);

